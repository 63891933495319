import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import ToyList from "../components/ToyList"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Home" />
    <ToyList data={data} />
    {/*<Link to="/page-2/">Go to page 2</Link>*/}
  </Layout>
)

export default IndexPage

// This is identical to the query in templates/category.js except for the category filter and the Show_on_homepage filter
// TODO: some abstraction (fragment?)
export const pageQuery = graphql`
  query {
    toys: allAirtable(
      filter: {
        table: { eq: "Products" }
        data: {
          Show_on_homepage: { eq: true }
          Diameter_mm_calc: { gt: 0 }
          Published: { eq: true }
        }
      }
      sort: { order: ASC, fields: data___Diameter_mm_calc }
    ) {
      nodes {
        recordId
        fields {
          slug
          formattedPrice
          diameterInPixels
        }
        data {
          Brand {
            data {
              Name
            }
          }
          Image {
            localFiles {
              childImageSharp {
                original {
                  height
                  width
                }
                fixed(
                  height: 280
                  width: 280
                  fit: CONTAIN
                  background: "#FFFFFF"
                ) {
                  ...GatsbyImageSharpFixed
                }
                fluid(
                  maxHeight: 560
                  maxWidth: 560
                  fit: CONTAIN
                  background: "#FFFFFF"
                ) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          Diameter_mm_calc
          Length_mm_calc
          Name
          Price
          Diameter_in_Pixels
          # Materials
          Category
          # Features
        }
      }
    }
  }
`
